import "./App.css";
import Placeholder from "./Placeholder";

function App() {
  return (
    <div className="App" style={{ display: "flex" }}>
      <Placeholder />
    </div>
  );
}

export default App;
