import logo from "./logo.png";

function Placeholder() {
  return (
    <div
      className="App-header"
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          flex: "1 1 50%",
          display: "flex",
          justifyContent: "center",
          boxSizing: "border-box",
        }}
      >
        <img src={logo} className="App-logo" alt="logo" />
      </div>
    </div>
  );
}

export default Placeholder;
